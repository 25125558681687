import { Link, useNavigate } from 'react-router'
import styles from './CreateProductPage.module.scss'
import { IoChevronBack } from 'react-icons/io5'
import { Button, Checkbox, Input, InputNumber, message, Select } from 'antd'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { CREATE_PRODUCT } from '../../../../entities/product'
import { Image } from '../../../../shared/gql/graphql'
import { useState } from 'react'
import { GalleryModal } from '../../../../widgets/galleryModal'
import TextArea from 'antd/es/input/TextArea'
import cs from 'classnames'
import { serverUrl } from '../../../../shared/constatns/serverUrl'
import { TiDeleteOutline } from "react-icons/ti";


interface FormValues {
  name: string,
  price?: number,
  stock?: number,
  description?: string,
  category?: string,
  exclusive?: string,
  feature?: string,
  images?: number[],
  new?: boolean
}

export const CreateProductPage = () => {
  const navigate = useNavigate()
  const [createProduct] = useMutation(CREATE_PRODUCT)
  const [preview, setPreview] = useState<Image>()
  const [checkedImages, setCheckedImages] = useState<Image[]>([])
  const [previewGalleryOpen, setPreviewGalleryOpen] = useState<boolean>(false)
  const [isGalleryOpen, setIsGalleryOpen] = useState<boolean>(false)
    const {control, handleSubmit} = useForm<FormValues>({
      defaultValues: {
        name: undefined,
        price: undefined,
        stock: undefined,
        description: undefined,
        category: undefined,
        exclusive: undefined,
        feature: undefined,
        new: undefined,
        images: []
      }
    })

    const onSubmit: SubmitHandler<FormValues>  = async (data)=> {
      const {name} = data || {}
      if(name) {
        try {
          const res = await createProduct({variables: {input: {...data, preview: preview?.id, images: checkedImages?.map(({id})=> id)}}})
          message.success('Товар успешно добавлен')
          navigate('/products')
        } catch(e) {
          console.error(e)
          message.error('Ошибка сервера')
        }
      }
    }
   
  
  

  return (
    <main className={styles.root} >
      <GalleryModal checkedItem={preview} setCheckedItem={setPreview} isModalOpen={previewGalleryOpen} setIsModalOpen={setPreviewGalleryOpen} />
      <GalleryModal checkedItems={checkedImages} setCheckedItems={setCheckedImages} isModalOpen={isGalleryOpen} setIsModalOpen={setIsGalleryOpen} />
         <div className={styles.header} >
        <Link to={'/products'} className={styles.returnLink} ><IoChevronBack /></Link >
        <h1>Создание товара</h1>
        <Button onClick={handleSubmit(onSubmit)} type='primary' >Сохранить</Button>
      </div>

        <form className={styles.form}>
          <h2>Общее</h2>
           <ul className={styles.formItems} >
            <li className={cs(styles.formItem, styles.name)} >
              <label htmlFor="name">Название</label>
              <Controller
              name='name'
              control={control}
              render={({field})=> (
                <Input {...field} size='large' id='name' />
              )}
              />
            </li>
            <li className={cs(styles.formItem, styles.new)} >
              <label htmlFor="new">Новинка</label>
              <Controller
              name='new'
              control={control}
              render={({field})=> (
                <Checkbox checked={field.value} onChange={(e)=> field.onChange(e.target.checked)} id='new' />
              )}
              />
            </li>
            <li className={styles.formItem} >
              <label htmlFor="exclusive">Эксклюзив</label>
              <Controller
              name='exclusive'
              control={control}
              render={({field})=> (
                <Input {...field} size='large' id='exclusive' />
              )}
              />
            </li>
            <li className={cs(styles.formItem, styles.price)} >
              <label htmlFor="price">Цена</label>
              <Controller
              name='price'
              control={control}
              render={({field})=> (
                <InputNumber {...field} size='large' controls={false} id='price' />
              )}
              />
            </li>
            <li className={cs(styles.formItem, styles.stock)} >
              <label htmlFor="stock">Остаток</label>
              <Controller
              name='stock'
              control={control}
              render={({field})=> (
                <InputNumber {...field} size='large' id='stock' />
              )}
              />
            </li>
            <li className={cs(styles.formItem, styles.description)} >
              <label htmlFor="description">Описание</label>
              <Controller
              name='description'
              control={control}
              render={({field})=> (
                <TextArea autoSize={{minRows: 8}} onChange={(e)=> field.onChange(e.target.value)} value={field.value || ''}  id='description' />
              )}
              />
            </li>
            <li className={styles.formItem} >
              <label htmlFor="category">Категория</label>
              <Controller
              name='category'
              control={control}
              render={({field})=> (
                <Select {...field} size='large' placeholder={'Выберите категорию'} options={[
                  {value: 'Games'},
                  {value: 'Anime'},
                  {value: 'Animation'},
                  {value: 'Comics'},
                  {value: 'Movies'},
                  {value: 'Music'},
                  {value: 'SCIFI'},
                  {value: 'Sports'},
                  {value: 'Ad Icons'},
                  {value: 'Retro Toys'},
                ]} id='category' />
              )}
              />
            </li>

            <li className={styles.formItem} >
              <label htmlFor="feature">Особенность</label>
              <Controller
              name='feature'
              control={control}
              render={({field})=> (
                <Select {...field} size='large' placeholder={'Выберите особенность'} options={[
                  {value: 'GITD'},
                  {value: 'Flocked'},
                  {value: 'Chase'},
                  {value: 'Metallic'},
                  {value: 'Diamond'},
                  {value: 'Scented'},
                  {value: 'Glitter'},
                  {value: 'Black Light'},
                  {value: 'Grail'},
                  {value: 'Light and Sound'},
                  {value: 'Premium'},
                  {value: 'Plus'},
                  {value: 'Bloody'},
                  {value: 'NFT'},
                  {value: 'Comic Con'}
                ]} id='feature' />
              )}
              />
            </li>


           </ul>

           <div className={styles.images} >
            <h2>Превью</h2>
          {preview && <div className={styles.imageHolder} >
                    <button onClick={()=> setPreview(undefined)} type='button' className={styles.deleteImageBtn}  >
                      <TiDeleteOutline />
                    </button>
                    <img src={serverUrl + preview?.url} /></div>}
                    <Button size='large' className={styles.addPreviewBtn} type='primary' onClick={()=> setPreviewGalleryOpen(true)} >Добавить превью</Button>
          </div>

           <div className={styles.images} >

              <h2>Изображения</h2>

           {checkedImages.length > 0 && <ul className={styles.imagesList} >
              {
                checkedImages?.map(({id, url})=> (
                  <li key={id} className={styles.imageHolder} >
                    <button onClick={()=> setCheckedImages(prev=> prev.filter((item)=> item.id !== id))} type='button' className={styles.deleteImageBtn}  >
                      <TiDeleteOutline />
                    </button>
                    <img src={serverUrl + url} /></li>
                ))
              }
            </ul>}
            <Button size='large' className={styles.addImagesBtn} onClick={()=> setIsGalleryOpen(true)} type='primary' >Добавить изображения</Button>
           </div>
        </form>

    </main>
  )
}
