import { Button, Input, message, notification, Select } from 'antd'
import styles from './CreateUserPage.module.scss'
import { IoChevronBack } from "react-icons/io5";
import { Link, useNavigate } from 'react-router';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Role } from '../../../../shared/gql/graphql';
import { useMutation } from '@apollo/client';
import { CREATE_USER, GET_USERS } from '../../../../entities/users';
import { useEffect } from 'react';


type FormValues = {
  login?: string
  password?: string
  email?: string
  social?: string
  firstName?: string
  secondName?: string
  phone?: string
  country?: string
  city?: string
  street?: string
  house?: string
  building?: string
  entrance?: string
  floor?: string
  apartment?: string
  intercom?: string
  role?: Role 

}

export const CreateUserPage = () => {
  const navigate = useNavigate()
  const [createUser, result] = useMutation(CREATE_USER,
    {
      refetchQueries: [ 
        {query: GET_USERS}
      ]
    }
  )
  const { control, handleSubmit, formState: {errors}, setError } = useForm<FormValues>({
    defaultValues: {
      login: undefined,
      password: undefined,
      email: undefined,
      social: undefined,
      firstName: undefined,
      secondName: undefined,
      phone: undefined,
      country: undefined,
      city: undefined,
      street: undefined,
      house: undefined,
      building: undefined,
      entrance: undefined,
      floor: undefined,
      apartment: undefined,
      intercom: undefined,
      role: Role.User
    }
  })


  const onSubmit: SubmitHandler<FormValues> = async (input)=> {

      const {login, password, ...data} = input || {}

      if(login && password) {
     try {
      const res = await createUser({variables: {input: {...data, login, password}}})
      if(res?.data?.createUser?.id) {
       message.success('Пользователь успешно добавлен')
       navigate('/users')
      }
     } catch(e) {
       console.error(e)
       message.error('Ошибка сервера')
    }
      } 

      if(!login || !password) {
        if(!login) {
          setError('login', {message: 'Это поле обязательно!'})
        }
        if(!password) {
          setError('password', {message: 'Это поле обязательно!'})
        }
      }
      
  } 

    useEffect(()=> {
        if(result?.error?.message === 'User with this login already exists') {
          notification.error({description: 'Пользователь с таким логином уже существует', message: 'Ошибка сохранения' , placement: 'top'})
        }
    }, [result?.error?.message])

  return (
    <main>
      <div className={styles.header} >
        <Link to={'/users'} className={styles.returnLink} ><IoChevronBack /></Link >
        <h1>Создание пользователя</h1>

        <Button onClick={handleSubmit(onSubmit)} type='primary' >Сохранить</Button>
      </div>



      <form className={styles.form} >
        <div className={styles.formItems} >
          <h2 className={styles.formTitle} >Общие</h2>
          <div className={styles.formItem} >
            <label htmlFor="login">Логин</label>
            <Controller
              name='login'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='login' />
              )}
            />
            {errors?.login && <p className={styles.error} >{errors?.login?.message}</p>}
          </div>
          <div className={styles.formItem} >
            <label htmlFor="password">Пароль</label>
            <Controller
              name='password'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='password' />
              )}
            />
           {errors?.password && <p className={styles.error} >{errors?.password?.message}</p>}
          </div>
          <div className={styles.formItem} >
            <label htmlFor="firstName">Имя</label>
            <Controller
              name='firstName'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='firstName' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="secondName">Фамилия</label>
            <Controller
              name='secondName'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='secondName' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="phone">Телефон</label>
            <Controller
              name='phone'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='phone' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="email">Email</label>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='email' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="social">Ссылка на социальные сети</label>
            <Controller
              name='social'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='social' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="role">Роль</label>
            <Controller
              name='role'
              control={control}
              render={({ field }) => (
                <Select size='large' options={[
                  { label: 'Клиент', value: 'USER' },
                  { label: 'Администратор', value: 'ADMIN' }
                ]} {...field} id='role' />
              )}
            />
          </div>
          <h2 className={styles.formTitle} >Адрес</h2>
          <div className={styles.formItem} >
            <label htmlFor="country">Страна</label>
            <Controller
              name='country'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='country' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="city">Город</label>
            <Controller
              name='city'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='city' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="street">Улица</label>
            <Controller
              name='street'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='street' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="house">Дом</label>
            <Controller
              name='house'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='house' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="building">Строение</label>
            <Controller
              name='building'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='building' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="entrance">Подъезд</label>
            <Controller
              name='entrance'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='entrance' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="floor">Этаж</label>
            <Controller
              name='floor'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='floor' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="apartment">Квартира</label>
            <Controller
              name='apartment'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='apartment' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="intercom">Домофон</label>
            <Controller
              name='intercom'
              control={control}
              render={({ field }) => (
                <Input {...field} size='large' id='intercom' />
              )}
            />
          </div>

        </div>
      </form>

    </main>
  )
}
