import { Button, Input } from 'antd'
import styles from './AuthPage.module.scss'
import SignIn from '../signIn'
import { useState } from 'react'
import SignUp from '../signUp'
import { useQuery } from '@apollo/client'
import { CHECK_SUPERUSER } from '../../../../entities/users/api'



export const AuthPage = () => {
 const {data} = useQuery(CHECK_SUPERUSER)

  return (
    <main className={styles.root} >
       {data?.checkSuperUser ? <SignIn  /> :
       <SignUp />}
    </main>
  )
}
