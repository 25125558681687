import { Link, useLocation, useNavigate } from 'react-router'
import styles from './Navbar.module.scss'
import cs from 'classnames'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ADMIN } from '../../../entities/users'
import { Button } from 'antd'
import { SIGN_OUT } from '../../../entities/auth'

const navList = [
  {
    label: 'Пользователи',
    href: '/users'
  },
  {
    label: 'Товары',
    href: '/products'
  },
  {
    label: 'Галерея',
    href: '/gallery'
  }
]

export const Navbar = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const pathname = location.pathname.split('/')[1]
  const {data} = useQuery(GET_ADMIN)
  const [signOut] = useMutation(SIGN_OUT, {
    refetchQueries: [GET_ADMIN]
  })


  const handleSignOut = async ()=> {
        const res = await signOut()
        if(res?.data?.signOut) {
          localStorage.removeItem('jwt')
          navigate('/auth', {replace: true})
        }
  }

  return (
    <nav className={styles.root} >
       <div className={styles.header} >
         <div className={styles.login} >{data?.getAdmin?.login}</div>
         <Button onClick={handleSignOut} >Выйти</Button>
       </div>
      <ul className={styles.list} >
        {
          navList.map(({label, href})=> (
            <li className={cs(styles.item, href === `/${pathname}` && styles.item__active)} key={href} >
              <Link to={href}>
              {label}
              </Link>
            </li>
          ))
        }
      </ul>
    </nav>
  )
}
