import { graphql } from "../../../shared/gql";




export const GET_IMAGES = graphql(`
     query getImages($input: GetImagesArgs) {
        images(params: $input) {
          data {
            id
            name
            url
            createdAt
            updatedAt
          },
          meta {
            pagination {
                page
                pageSize
                pageCount
                total
             }
          }
        }
     }
    `)

    export const REMOVE_IMAGE = graphql(`
     mutation removeImage($input: Int!) {
        removeImage(id: $input)
    }
      `)