import { Button, Popconfirm, Select, Table, TableColumnsType } from 'antd';
import styles from './UsersPage.module.scss';
import { Link, useNavigate } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS, REMOVE_USER } from '../../../../entities/users';
import { useEffect, useState } from 'react';
import Search from 'antd/es/input/Search';
import { Sort } from '../../../../shared/gql/graphql';
import { useDebounce } from 'use-debounce';


export const UsersPage = () => {
  const [page, setPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [orderBy, SetOrderBy] = useState<{field: string, value: Sort}>({field: 'createdAt', value: Sort.Asc})
  const [search, setSearch] = useState<string>()
  const [debouncedSearch] = useDebounce(search, 500)
  const [checkedItems, setCheckedItems] = useState<number[]>([])
  const navigate = useNavigate()
  const [removeUser] = useMutation(REMOVE_USER)
  const {data, loading, refetch} = useQuery(GET_USERS, {variables: {input: {
    pagination: {page, pageSize},
    orderBy,
    search: debouncedSearch
  }}, fetchPolicy: 'network-only'})

  const user = data?.users?.data?.[0]
  const pagination = data?.users?.meta?.pagination


  const columns: TableColumnsType<typeof user> = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Логин',
      dataIndex: 'login'
    },
    {
      title: 'Роль',
      dataIndex: 'role',
      render: (value)=> {
        switch(value) {
          case 'SUPER_ADMIN': return 'супер администратор'
          case 'ADMIN': return 'администратор'
          case 'USER': return 'клиент'
        }
      }
    },
    {
      title: 'Имя',
      dataIndex: 'firstName'
    },
    {
      title: 'Фамилия',
      dataIndex: 'secondName'
    },
    {
      title: 'Email',
      dataIndex: 'email'
    },
    {
      title: 'Телефон',
      dataIndex: 'Phone'
    },
    {
      title: 'Страна',
      dataIndex: 'country'
    },
    {
      title: 'Город',
      dataIndex: 'city'
    }

  ]

  useEffect(()=> {
    const page = sessionStorage.getItem('table-page')
    const pageSize = sessionStorage.getItem('table-pageSize')
    if(page) {
      setPage(+page)
    }
    if(pageSize) {
      setPageSize(+pageSize)
    }
  }, [])

  const onPageChange = (page: number, pageSize: number)=> {
    setPage(page)
    setPageSize(pageSize)
    sessionStorage.setItem('table-page', page.toString())
    sessionStorage.setItem('table-pageSize', pageSize.toString())
  } 

  useEffect(()=> {
        if(pagination?.pageCount && page > pagination.pageCount) {
          setPage(pagination.pageCount)
          sessionStorage.setItem('table-page', pagination.pageCount.toString())
        }
  }, [pagination?.pageCount])


  const onItemsDelete = async ()=> {
    await Promise.all(checkedItems?.map(async (id)=> {
      try {
       await removeUser({variables: {input: id}})
      } catch(e) {
       console.error(e)
      }
     } ))

     refetch()
     setCheckedItems([])
  }

  const handleOrderBy = (e: string)=> {
      const field = e.split(':')[0]
      const value = e.split(':')[1]
      SetOrderBy({field, value: value as Sort})
  }

 

  return (
    <main className={styles.root} >
      <div className={styles.header} >
        <h1>Пользователи</h1>
        <div className={styles.btnsWrapper} >
          { checkedItems.length > 0 && <Popconfirm
           title='Удаление'
           description='Вы действительно хотите удалить выбранные записи?'
           onConfirm={onItemsDelete}
          ><Button type='primary' danger >Удалить выбранные</Button></Popconfirm>}
           <Link to='/users/create' ><Button type='primary' >Добавить</Button></Link>
        </div>
      </div>
       <div className={styles.content} >
        <div className={styles.filters} >
          <Search onChange={(e)=> setSearch(e.target.value)} placeholder='Поиск' className={styles.search} />
          <div className={styles.sortWrapper} >
            <p>Сортировать по:</p>
            <Select onChange={handleOrderBy} className={styles.sort} defaultValue={'createdAt:asc'} options={[
              {label: 'Дата (возрастание)', value: 'createdAt:asc'},
              {label: 'Дата (убывание)', value: 'createdAt:desc'},
              {label: 'Логин (возрастание)', value: 'login:asc'},
              {label: 'Логин (убывание)', value: 'login:desc'}
            ]} />
          </div>
        </div>
        <Table
        className={styles.table}
        loading={loading}
        columns={columns}
        rowSelection={{ type: 'checkbox', onChange: (items)=> setCheckedItems(items as number[]) }}
        pagination={{hideOnSinglePage: data?.users?.data && (data?.users?.data?.length < 10), showSizeChanger: true, defaultPageSize: 10, total: pagination?.total, pageSize, current: pagination?.page, onChange: onPageChange }}
        onRow={(item)=> ({onDoubleClick: ()=> navigate(`/users/${item?.id}`)})}
        dataSource={data?.users?.data?.map((item)=> ({...item, key: item?.id}))}
        />
       </div>
    </main>
  )
}
