/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 * Learn more about it here: https://the-guild.dev/graphql/codegen/plugins/presets/preset-client#reducing-bundle-size
 */
const documents = {
    "\n    mutation signUp($input: SignUpInput!) {\n        signUp(signUpInput: $input) {\n            jwt\n        }\n    }\n        ": types.SignUpDocument,
    "\n    mutation signIn($input: SignInInput!) {\n        signIn(signInInput: $input) {\n            jwt\n        }      \n    }\n        ": types.SignInDocument,
    "\n    mutation signOut {\n        signOut\n    }\n    ": types.SignOutDocument,
    "\n        mutation tokensRefresh {\n            tokensRefresh {\n                jwt\n            }\n        }\n        ": types.TokensRefreshDocument,
    "\n     query getImages($input: GetImagesArgs) {\n        images(args: $input) {\n          data {\n            id\n            name\n            url\n            createdAt\n            updatedAt\n          },\n          meta {\n            pagination {\n                page\n                pageSize\n                pageCount\n                total\n             }\n          }\n        }\n     }\n    ": types.GetImagesDocument,
    "\n     mutation removeImage($input: Int!) {\n        removeImage(id: $input)\n    }\n      ": types.RemoveImageDocument,
    "\n       mutation createProduct($input: CreateProductInput!) {\n        createProduct(createProductInput: $input) {\n            id\n            name\n            price\n            stock\n            feature\n            category\n            exclusive\n            images {\n                id\n                name\n                url\n            }\n            createdAt\n        }\n       }\n    ": types.CreateProductDocument,
    "\n    mutation updateProduct($input: UpdateProductInput!) {\n     updateProduct(updateProductInput: $input) {\n         id\n         name\n         price\n           stock\n            feature\n            category\n            exclusive\n         images {\n             id\n             name\n             url\n         }\n         createdAt\n     }\n    }\n ": types.UpdateProductDocument,
    "\n    mutation removeProduct($input: Int!) {\n        removeProduct(id: $input) {\n            id\n        }\n    }\n ": types.RemoveProductDocument,
    "\n    query getProducts($input: GetProductsArgs!) {\n        products(args: $input) {\n          data {\n            id\n            name\n            price\n            stock\n            feature\n            category\n            exclusive\n            new\n            preview {\n                id\n                name\n                url\n            }\n            images {\n                id\n                name\n                url\n            }\n            createdAt\n          }\n          meta {\n           pagination {\n            page\n            pageSize\n            pageCount\n            total\n           }\n          }\n        }\n    }\n    ": types.GetProductsDocument,
    "\n    query getProduct($input: Int!) {\n        product(id: $input) {\n            id\n            name\n            price\n            description\n            stock\n            feature\n            category\n            exclusive\n            new\n            preview {\n                id\n                name\n                url\n            }\n            images {\n                id\n                name\n                url\n                createdAt\n            }\n            createdAt\n          }\n    }\n    ": types.GetProductDocument,
    "\nmutation uploadFile($file: [Upload!]!) {\n    uploadFile(file: $file) \n}": types.UploadFileDocument,
    "\n        query getAdmin {\n            getAdmin {\n                id\n                login\n            }\n        }\n    ": types.GetAdminDocument,
    "\n        query checkSuperUser {\n            checkSuperUser\n        }\n    ": types.CheckSuperUserDocument,
    "\n    mutation createUser($input: CreateUserInput!) {\n        createUser(createUserInput: $input){\n            id\n        }\n    }\n    ": types.CreateUserDocument,
    "\n    mutation updateUser($input: UpdateUserInput!) {\n        updateUser(updateUserInput: $input){\n            id\n        }\n    }\n    ": types.UpdateUserDocument,
    "\n    mutation removeUser($input: Int!) {\n        removeUser(id: $input) {\n            id\n        }\n    }\n    ": types.RemoveUserDocument,
    "\n    query getUsers($input: GetUsersArgs) {\n          users(args: $input) {\n             data {\n                id\n             login\n             email\n             social\n             firstName\n             secondName\n             phone\n             country\n             city\n             street\n             house\n             building\n             entrance\n             floor\n             apartment\n             intercom\n             role\n             },\n              meta {\n                pagination {\n                page\n                pageSize\n                pageCount\n                total\n             }\n              }\n          }\n        }\n    ": types.GetUsersDocument,
    "\n        query getUser($input: Int!) {\n            user(id: $input) {\n             id\n             login\n             email\n             social\n             firstName\n             secondName\n             phone\n             country\n             city\n             street\n             house\n             building\n             entrance\n             floor\n             apartment\n             intercom\n             role\n            }\n        }\n        ": types.GetUserDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation signUp($input: SignUpInput!) {\n        signUp(signUpInput: $input) {\n            jwt\n        }\n    }\n        "): (typeof documents)["\n    mutation signUp($input: SignUpInput!) {\n        signUp(signUpInput: $input) {\n            jwt\n        }\n    }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation signIn($input: SignInInput!) {\n        signIn(signInInput: $input) {\n            jwt\n        }      \n    }\n        "): (typeof documents)["\n    mutation signIn($input: SignInInput!) {\n        signIn(signInInput: $input) {\n            jwt\n        }      \n    }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation signOut {\n        signOut\n    }\n    "): (typeof documents)["\n    mutation signOut {\n        signOut\n    }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        mutation tokensRefresh {\n            tokensRefresh {\n                jwt\n            }\n        }\n        "): (typeof documents)["\n        mutation tokensRefresh {\n            tokensRefresh {\n                jwt\n            }\n        }\n        "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n     query getImages($input: GetImagesArgs) {\n        images(args: $input) {\n          data {\n            id\n            name\n            url\n            createdAt\n            updatedAt\n          },\n          meta {\n            pagination {\n                page\n                pageSize\n                pageCount\n                total\n             }\n          }\n        }\n     }\n    "): (typeof documents)["\n     query getImages($input: GetImagesArgs) {\n        images(args: $input) {\n          data {\n            id\n            name\n            url\n            createdAt\n            updatedAt\n          },\n          meta {\n            pagination {\n                page\n                pageSize\n                pageCount\n                total\n             }\n          }\n        }\n     }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n     mutation removeImage($input: Int!) {\n        removeImage(id: $input)\n    }\n      "): (typeof documents)["\n     mutation removeImage($input: Int!) {\n        removeImage(id: $input)\n    }\n      "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n       mutation createProduct($input: CreateProductInput!) {\n        createProduct(createProductInput: $input) {\n            id\n            name\n            price\n            stock\n            feature\n            category\n            exclusive\n            images {\n                id\n                name\n                url\n            }\n            createdAt\n        }\n       }\n    "): (typeof documents)["\n       mutation createProduct($input: CreateProductInput!) {\n        createProduct(createProductInput: $input) {\n            id\n            name\n            price\n            stock\n            feature\n            category\n            exclusive\n            images {\n                id\n                name\n                url\n            }\n            createdAt\n        }\n       }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation updateProduct($input: UpdateProductInput!) {\n     updateProduct(updateProductInput: $input) {\n         id\n         name\n         price\n           stock\n            feature\n            category\n            exclusive\n         images {\n             id\n             name\n             url\n         }\n         createdAt\n     }\n    }\n "): (typeof documents)["\n    mutation updateProduct($input: UpdateProductInput!) {\n     updateProduct(updateProductInput: $input) {\n         id\n         name\n         price\n           stock\n            feature\n            category\n            exclusive\n         images {\n             id\n             name\n             url\n         }\n         createdAt\n     }\n    }\n "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation removeProduct($input: Int!) {\n        removeProduct(id: $input) {\n            id\n        }\n    }\n "): (typeof documents)["\n    mutation removeProduct($input: Int!) {\n        removeProduct(id: $input) {\n            id\n        }\n    }\n "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getProducts($input: GetProductsArgs!) {\n        products(args: $input) {\n          data {\n            id\n            name\n            price\n            stock\n            feature\n            category\n            exclusive\n            new\n            preview {\n                id\n                name\n                url\n            }\n            images {\n                id\n                name\n                url\n            }\n            createdAt\n          }\n          meta {\n           pagination {\n            page\n            pageSize\n            pageCount\n            total\n           }\n          }\n        }\n    }\n    "): (typeof documents)["\n    query getProducts($input: GetProductsArgs!) {\n        products(args: $input) {\n          data {\n            id\n            name\n            price\n            stock\n            feature\n            category\n            exclusive\n            new\n            preview {\n                id\n                name\n                url\n            }\n            images {\n                id\n                name\n                url\n            }\n            createdAt\n          }\n          meta {\n           pagination {\n            page\n            pageSize\n            pageCount\n            total\n           }\n          }\n        }\n    }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getProduct($input: Int!) {\n        product(id: $input) {\n            id\n            name\n            price\n            description\n            stock\n            feature\n            category\n            exclusive\n            new\n            preview {\n                id\n                name\n                url\n            }\n            images {\n                id\n                name\n                url\n                createdAt\n            }\n            createdAt\n          }\n    }\n    "): (typeof documents)["\n    query getProduct($input: Int!) {\n        product(id: $input) {\n            id\n            name\n            price\n            description\n            stock\n            feature\n            category\n            exclusive\n            new\n            preview {\n                id\n                name\n                url\n            }\n            images {\n                id\n                name\n                url\n                createdAt\n            }\n            createdAt\n          }\n    }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\nmutation uploadFile($file: [Upload!]!) {\n    uploadFile(file: $file) \n}"): (typeof documents)["\nmutation uploadFile($file: [Upload!]!) {\n    uploadFile(file: $file) \n}"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query getAdmin {\n            getAdmin {\n                id\n                login\n            }\n        }\n    "): (typeof documents)["\n        query getAdmin {\n            getAdmin {\n                id\n                login\n            }\n        }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query checkSuperUser {\n            checkSuperUser\n        }\n    "): (typeof documents)["\n        query checkSuperUser {\n            checkSuperUser\n        }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation createUser($input: CreateUserInput!) {\n        createUser(createUserInput: $input){\n            id\n        }\n    }\n    "): (typeof documents)["\n    mutation createUser($input: CreateUserInput!) {\n        createUser(createUserInput: $input){\n            id\n        }\n    }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation updateUser($input: UpdateUserInput!) {\n        updateUser(updateUserInput: $input){\n            id\n        }\n    }\n    "): (typeof documents)["\n    mutation updateUser($input: UpdateUserInput!) {\n        updateUser(updateUserInput: $input){\n            id\n        }\n    }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    mutation removeUser($input: Int!) {\n        removeUser(id: $input) {\n            id\n        }\n    }\n    "): (typeof documents)["\n    mutation removeUser($input: Int!) {\n        removeUser(id: $input) {\n            id\n        }\n    }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n    query getUsers($input: GetUsersArgs) {\n          users(args: $input) {\n             data {\n                id\n             login\n             email\n             social\n             firstName\n             secondName\n             phone\n             country\n             city\n             street\n             house\n             building\n             entrance\n             floor\n             apartment\n             intercom\n             role\n             },\n              meta {\n                pagination {\n                page\n                pageSize\n                pageCount\n                total\n             }\n              }\n          }\n        }\n    "): (typeof documents)["\n    query getUsers($input: GetUsersArgs) {\n          users(args: $input) {\n             data {\n                id\n             login\n             email\n             social\n             firstName\n             secondName\n             phone\n             country\n             city\n             street\n             house\n             building\n             entrance\n             floor\n             apartment\n             intercom\n             role\n             },\n              meta {\n                pagination {\n                page\n                pageSize\n                pageCount\n                total\n             }\n              }\n          }\n        }\n    "];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n        query getUser($input: Int!) {\n            user(id: $input) {\n             id\n             login\n             email\n             social\n             firstName\n             secondName\n             phone\n             country\n             city\n             street\n             house\n             building\n             entrance\n             floor\n             apartment\n             intercom\n             role\n            }\n        }\n        "): (typeof documents)["\n        query getUser($input: Int!) {\n            user(id: $input) {\n             id\n             login\n             email\n             social\n             firstName\n             secondName\n             phone\n             country\n             city\n             street\n             house\n             building\n             entrance\n             floor\n             apartment\n             intercom\n             role\n            }\n        }\n        "];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;