import { useEffect, useState } from 'react'
import styles from './GalleryPage.module.scss'
import { Button, Checkbox, Image, Pagination, Popconfirm, Select } from 'antd'
import AddImagesModal from '../addImagesModal'
import { useMutation, useQuery } from '@apollo/client'
import { GET_IMAGES, REMOVE_IMAGE } from '../../../../entities/images'
import { serverUrl } from '../../../../shared/constatns/serverUrl'
import Search from 'antd/es/input/Search'
import { Sort } from '../../../../shared/gql/graphql'
import { useDebounce } from 'use-debounce'

export const GalleryPage = () => {
  const [checkedItems, setCheckedItems] = useState<React.Key[]>([])
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [pagination, setPagination] = useState<{page: number, pageSize: number}>({page: 1, pageSize: 24})
  const [search, setSearch] = useState<string>()
  const [debouncedSearch] = useDebounce(search, 500)
  const [orderBy, SetOrderBy] = useState<{field: string, value: Sort}>({field: 'createdAt', value: Sort.Asc})
  const [removeImage] = useMutation(REMOVE_IMAGE, 
    {refetchQueries: [{query: GET_IMAGES}]}
  )
  const {data, refetch} = useQuery(GET_IMAGES, {
    variables: {input: {pagination, orderBy, search: debouncedSearch}},
    fetchPolicy: 'network-only'
  })
 const images = data?.images?.data
 const meta = data?.images?.meta



  const handleCheck = (checked: boolean, id: number)=> {
   
      if(checked) {
         setCheckedItems(prev=> [...prev, id])
      } else {
        setCheckedItems(prev => prev.filter((item)=> item !== id))
      }
  }

  const handleCheckAll = (value: boolean)=> {
    if(value) {
      const items = images?.map(({id})=> id as React.Key)
      if(items) {
        setCheckedItems(items)
      } 
    } else {
      setCheckedItems([])
    }
  }

  const handleImagesDelete = async ()=> {
     try {
      await Promise.all(
        checkedItems?.map(async (id)=> {
           await removeImage({variables: {input: id as number}})
        })
      )
      refetch()
      setCheckedItems([])
     } catch(e) {
       console.error(e)
     }
  
  } 

  const handlePageChange = (page: number, pageSize: number)=> {
           setPagination({page, pageSize})
  }

    const handleOrderBy = (e: string)=> {
        const field = e.split(':')[0]
        const value = e.split(':')[1]
        SetOrderBy({field, value: value as Sort})
    }

      useEffect(()=> {
            if(meta?.pagination?.pageCount && (pagination.page > meta?.pagination?.pageCount)) {
              setPagination(prev=> ({...prev, page: meta?.pagination?.pageCount ?? pagination.page}))
            }
      }, [meta?.pagination?.pageCount])
    

  return (
    <main className={styles.root} >
      <AddImagesModal refetch={refetch} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <div className={styles.header} >
        <h1>Галерея</h1>
        <div className={styles.btnsWrapper} >
          { checkedItems.length > 0 && <Popconfirm
           title='Удаление'
           description='Вы действительно хотите удалить выбранные записи?'
           onConfirm={handleImagesDelete}
          ><Button type='primary' danger >Удалить выбранные</Button></Popconfirm>}
         <Button onClick={()=> setIsModalOpen(true)} type='primary' >Добавить</Button>
        </div>
      </div>
      <div className={styles.content} >
        <div className={styles.filters} >
        <div className={styles.filters} >
          <Search onChange={(e)=> setSearch(e.target.value)} placeholder='Поиск' className={styles.search} />
          <div className={styles.checkAll} >
              <Checkbox checked={checkedItems.length === images?.length && (images.length > 0)} onChange={(e)=> handleCheckAll(e.target.checked)} id='checkAll' /> 
              <label htmlFor="checkAll">Выбрать все</label>
            </div>
          <div className={styles.sortWrapper} >
            <p>Сортировать по:</p>
            <Select onChange={handleOrderBy} className={styles.sort} defaultValue={'createdAt:asc'} options={[
              {label: 'Дата (возрастание)', value: 'createdAt:asc'},
              {label: 'Дата (убывание)', value: 'createdAt:desc'},
              {label: 'Название (возрастание)', value: 'name:asc'},
              {label: 'Название (убывание)', value: 'name:desc'}
            ]} />
          </div>
        </div>
        </div>
        <ul className={styles.list} >
          {
           data?.images?.data?.map(({id, name, url})=> (
              <li className={styles.item} key={id} > 
              <Checkbox checked={checkedItems.includes(id)} onChange={(e)=> handleCheck(e.target.checked, id)} className={styles.checkbox} />
                <div className={styles.imageHolder} >
                  <Image height={'100%'} width={'100%'} className={styles.image} src={serverUrl + url} />
                </div>
                <p className={styles.title} >{name}</p>
                </li>
           ))
          }
        </ul>
       <Pagination hideOnSinglePage={pagination.page === 1 && images && (images?.length <= 24)} pageSizeOptions={[24, 48, 96, 192]} onChange={handlePageChange} className={styles.pagination} showSizeChanger current={pagination.page} pageSize={pagination.pageSize} total={meta?.pagination?.total} />
      </div>
    </main>
  )
}
