import { Button, Input, message, Popconfirm, Select } from 'antd'
import styles from './UserPage.module.scss'
import { IoChevronBack } from "react-icons/io5";
import { Link, useLocation, useNavigate } from 'react-router';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Role } from '../../../../shared/gql/graphql';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USER, GET_USERS, REMOVE_USER, UPDATE_USER } from '../../../../entities/users';
import { useEffect } from 'react';


type FormValues = {
  id: number,
  login?: string
  password?: string
  email?: string
  social?: string
  firstName?: string
  secondName?: string
  phone?: string
  country?: string
  city?: string
  street?: string
  house?: string
  building?: string
  entrance?: string
  floor?: string
  apartment?: string
  intercom?: string
  role?: Role 

}

export const UserPage = () => {
  const location = useLocation()
  const id = +location.pathname.split('/')[2]
  const navigate = useNavigate()
  const {data} = useQuery(GET_USER, {variables: {input: id}})
  const user = data?.user
  const [updateUser, {error: updateError}] = useMutation(UPDATE_USER, {
    refetchQueries: [
      {query: GET_USERS}
    ]
  })
  const [removeUser] = useMutation(REMOVE_USER, {
    refetchQueries: [
      {query: GET_USERS}
    ]
  })
 
  const { control, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      id: id,
      login: undefined,
      password: undefined,
      email: undefined,
      social: undefined,
      firstName: undefined,
      secondName: undefined,
      phone: undefined,
      country: undefined,
      city: undefined,
      street: undefined,
      house: undefined,
      building: undefined,
      entrance: undefined,
      floor: undefined,
      apartment: undefined,
      intercom: undefined,
      role: Role.User
    }
  })
 

   useEffect(()=> {
    if(user)  {
      const keys = Object.keys(user).filter((item)=> item !== '__typename')
        keys.map((key: any)=> {
         setValue(key, data.user[key as keyof typeof user])
        })
    }
   }, [data])

    

  const onSubmit: SubmitHandler<FormValues> = async (input)=> {
      const {login, password, ...data} = input || {}
     try {
      const {data: result, errors} = await updateUser({variables: {input: {...data, login, password}}})
      if(result?.updateUser?.id) {
       message.success('Пользователь успешно обновлен!')
       navigate('/users')
      } else {
       message.error('Ошибка сервера')
      }
     } catch(e) {
      console.error(e)
     }
  } 

  useEffect(()=> {
   if(updateError?.message === 'Forbidden') {
    message.error('Нет прав для изменения!')
   }
  }, [updateError])



  const handleRemove = async (id: number)=> {
     try {
      const res = await removeUser({variables: {input: id}})
      if(res.data?.removeUser?.id) {
         message.success('Пользователь успешно удален!')
         navigate('/users')
      } else {
       message.error('Ошибка сервера')
      }
     } catch(e) {
      console.error(e)
     }
  }

  return (
    <main>
      <div className={styles.header} >
        <Link to={'/users'} className={styles.returnLink} ><IoChevronBack /></Link >
        <h1>Редактирование пользователя {data?.user?.login}</h1>
        <div className={styles.btnsWrapper} >
          <Popconfirm
          title='Удаление'
          description='Вы действительно хотите удалить запись?'
          onConfirm={()=> handleRemove(id)}
          ><Button type='primary' danger >Удалить</Button></Popconfirm>
          <Button onClick={handleSubmit(onSubmit)} type='primary' >Сохранить</Button>
        </div>
      </div>



      <form className={styles.form} >
        <div className={styles.formItems} >
          <h2 className={styles.formTitle} >Общие</h2>
          <div className={styles.formItem} >
            <label htmlFor="login">Логин</label>
            <Controller
              name='login'
              control={control}
              render={({ field }) => (
                <Input {...field} id='login' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="password">Пароль</label>
            <Controller
              name='password'
              control={control}
              render={({ field }) => (
                <Input {...field} placeholder='**********' id='password' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="firstName">Имя</label>
            <Controller
              name='firstName'
              control={control}
              render={({ field }) => (
                <Input {...field} id='firstName' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="secondName">Фамилия</label>
            <Controller
              name='secondName'
              control={control}
              render={({ field }) => (
                <Input {...field} id='secondName' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="phone">Телефон</label>
            <Controller
              name='phone'
              control={control}
              render={({ field }) => (
                <Input {...field} id='phone' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="email">Email</label>
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <Input {...field} id='email' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="social">Ссылка на социальные сети</label>
            <Controller
              name='social'
              control={control}
              render={({ field }) => (
                <Input {...field} id='social' />
              )}
            />
          </div>
      {  data?.user?.role !== 'SUPER_ADMIN' && <div className={styles.formItem} >
            <label htmlFor="role">Роль</label>
            <Controller
              name='role'
              control={control}
              render={({ field }) => (
                <Select options={[
                  { label: 'Клиент', value: 'USER' },
                  { label: 'Админ', value: 'ADMIN' }
                ]} {...field} id='role' />
              )}
            />
          </div>}
          <h2 className={styles.formTitle} >Адрес</h2>
          <div className={styles.formItem} >
            <label htmlFor="country">Страна</label>
            <Controller
              name='country'
              control={control}
              render={({ field }) => (
                <Input {...field} id='country' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="city">Город</label>
            <Controller
              name='city'
              control={control}
              render={({ field }) => (
                <Input {...field} id='city' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="street">Улица</label>
            <Controller
              name='street'
              control={control}
              render={({ field }) => (
                <Input {...field} id='street' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="house">Дом</label>
            <Controller
              name='house'
              control={control}
              render={({ field }) => (
                <Input {...field} id='house' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="building">Строение</label>
            <Controller
              name='building'
              control={control}
              render={({ field }) => (
                <Input {...field} id='building' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="entrance">Подъезд</label>
            <Controller
              name='entrance'
              control={control}
              render={({ field }) => (
                <Input {...field} id='entrance' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="floor">Этаж</label>
            <Controller
              name='floor'
              control={control}
              render={({ field }) => (
                <Input {...field} id='floor' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="apartment">Квартира</label>
            <Controller
              name='apartment'
              control={control}
              render={({ field }) => (
                <Input {...field} id='apartment' />
              )}
            />
          </div>
          <div className={styles.formItem} >
            <label htmlFor="intercom">Домофон</label>
            <Controller
              name='intercom'
              control={control}
              render={({ field }) => (
                <Input {...field} id='intercom' />
              )}
            />
          </div>

        </div>
      </form>

    </main>
  )
}