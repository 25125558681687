import logo from './logo.svg';
import Router from './Router';
import ApolloProvider from './shared/providers/apolloProvider';
import './shared/styles/global.scss';
import { ConfigProvider } from 'antd';
import ru_RU from 'antd/locale/ru_RU'

function App() {
  return (
    <div className="App">
      <ApolloProvider>
        <ConfigProvider locale={ru_RU} >
     <Router/>
     </ConfigProvider>
     </ApolloProvider>
    </div>
  );
}

export default App;
