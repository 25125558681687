import { Navigate } from 'react-router'
import styles from './HomePage.module.scss'



export const HomePage = () => {
  
  return (
    <Navigate to={'/users'} replace={true} />
  )
}

