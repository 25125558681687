import { Checkbox, Image, Modal, Pagination, Select } from 'antd'
import styles from './GalleryModal.module.scss'
import { FC, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { GET_IMAGES } from '../../../entities/images'
import { Sort } from '../../../shared/gql/graphql'
import Search from 'antd/es/input/Search'
import { serverUrl } from '../../../shared/constatns/serverUrl'
import { useDebounce } from 'use-debounce'

interface IProps {
isModalOpen: boolean
setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
onChange: (value:React.Key[]) => void
}

export const GalleryModal: FC<IProps> = ({isModalOpen, setIsModalOpen, onChange}) => {
   const [checkedItems, setCheckedItems] = useState<React.Key[]>([])
    const [pagination, setPagination] = useState<{page: number, pageSize: number}>({page: 1, pageSize: 28})
    const [search, setSearch] = useState<string>()
    const [debouncedSearch] = useDebounce(search, 500)
    const [orderBy, SetOrderBy] = useState<{field: string, value: Sort}>({field: 'createdAt', value: Sort.Asc})
    const {data} = useQuery(GET_IMAGES, {
      variables: {input: {pagination, orderBy, search: debouncedSearch}},
      fetchPolicy: 'network-only'
    })
   const images = data?.images?.data
   const meta = data?.images?.meta
   

  
    const handleCheck = (checked: boolean, id: number)=> {
        if(checked) {
           setCheckedItems(prev=> [...prev, id])
        } else {
          setCheckedItems(prev => prev.filter((item)=> item !== id))
        }
    }

    const handleCheckAll = (value: boolean)=> {
      if(value) {
        const items = images?.map(({id})=> id as React.Key)
        if(items) {
          setCheckedItems(items)
        } 
      } else {
        setCheckedItems([])
      }
    }

   useEffect(()=> {
    if(onChange) {
      onChange(checkedItems)
    }
   }, [checkedItems])
  
    const handlePageChange = (page: number, pageSize: number)=> {
             setPagination({page, pageSize})
    }
  
      const handleOrderBy = (e: string)=> {
          const field = e.split(':')[0]
          const value = e.split(':')[1]
          SetOrderBy({field, value: value as Sort})
      }
  
        useEffect(()=> {
              if(meta?.pagination?.pageCount && pagination.page > meta?.pagination?.pageCount) {
                setPagination(prev=> ({...prev, page: meta?.pagination?.pageCount ?? pagination.page}))
              }
        }, [meta?.pagination?.pageCount])
      
  return (
    <Modal onOk={()=> {setIsModalOpen(false); setCheckedItems([])}} okText='Добавить выбранные' centered onCancel={()=> setIsModalOpen(false)} className={styles.root} width={'97vw'} open={isModalOpen} title='Выбрать изображения' >
      <div className={styles.content} >
      <div className={styles.filters} >
        <div className={styles.filters} >
          <Search onChange={(e)=> setSearch(e.target.value)} placeholder='Поиск' className={styles.search} />
            <div className={styles.checkAll} >
              <Checkbox checked={checkedItems.length === images?.length && (images.length > 0)} onChange={(e)=> handleCheckAll(e.target.checked)} id='checkAll' /> 
              <label htmlFor="checkAll">Выбрать все</label>
            </div>
          <div className={styles.sortWrapper} >
            <p>Сортировать по:</p>
            <Select onChange={handleOrderBy} className={styles.sort} defaultValue={'createdAt:asc'} options={[
              {label: 'Дата (возрастание)', value: 'createdAt:asc'},
              {label: 'Дата (убывание)', value: 'createdAt:desc'},
              {label: 'Название (возрастание)', value: 'name:asc'},
              {label: 'Название (убывание)', value: 'name:desc'}
            ]} />
          </div>
        </div>
        </div>
        <ul className={styles.list} >
          {
           data?.images?.data?.map(({id, name, url})=> (
              <li className={styles.item} key={id} > 
              <Checkbox checked={checkedItems.includes(id)} onChange={(e)=> handleCheck(e.target.checked, id)} className={styles.checkbox} />
                <div className={styles.imageHolder} >
                  <Image height={'100%'} width={'100%'} className={styles.image} src={serverUrl + url} />
                </div>
                <p >{name}</p>
                </li>
           ))
          }
        </ul>
       <Pagination hideOnSinglePage={pagination.page === 1 && images && (images?.length <= 28)} pageSizeOptions={[28, 56, 112, 224]} onChange={handlePageChange} className={styles.pagination} showSizeChanger current={pagination.page} pageSize={pagination.pageSize} total={meta?.pagination?.total} />
      </div>
    </Modal>
  )
}
