import { Button, Input } from 'antd'
import styles from './SignUp.module.scss'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useMutation } from '@apollo/client'
import { SIGN_UP } from '../../../../entities/auth'
import { useNavigate } from 'react-router'
import { Role } from '../../../../shared/gql/graphql'
import { GET_ADMIN } from '../../../../entities/users'




type FormValues = {
  login?: string,
  password?: string,
  role: Role
}

const SignUp = () => {
  const {control, watch, handleSubmit} = useForm<FormValues>({
    defaultValues: {
      login: undefined,
      password: undefined,
      role: Role.SuperAdmin
    }
  })

 
  const [signUp] = useMutation(SIGN_UP ,
     {
      refetchQueries: [
        {query: GET_ADMIN}
      ]
     }
  )
  const navigate = useNavigate()
  const onSubmit: SubmitHandler<FormValues> = async (data)=> {
      const {login, password, role} = data
       if(login && password) {
        const {data} = await signUp({variables: {input: {login, password, role}}})
        const token = data?.signUp?.jwt
        if(token) {
          localStorage.setItem('jwt', token)
          navigate('/', {replace: true})
        }
       }
  }



  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
    <h1 className={styles.title} >Регистрация</h1>
    <div className={styles.formItems} >
      <div className={styles.formItem} >
        <label htmlFor="login">Логин</label>
         <Controller
         name='login'
         control={control}
         render={({field})=> (
          <Input {...field} id='login' type='text' />
         )}
         />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="password">Пароль</label>
        <Controller
         name='password'
         control={control}
         render={({field})=> (
          <Input {...field} id='password' type='text' />
         )}
         />
      </div>
    </div>
    <Button htmlType='submit' size='large' className={styles.submitBtn} type='primary' >Зарегестрироватся</Button>
   </form>
  )
}

export default SignUp