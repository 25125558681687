import { Route, Routes } from "react-router"
import { HomePage } from "./pages/homePage"
import { AuthPage } from "./pages/authPage"
import MainLayout from "./mainLayout"
import { UsersPage } from "./pages/users/usersPage"
import { CreateUserPage } from "./pages/users/createUserPage"
import { ProductsPage } from "./pages/products/productsPage"
import { UserPage } from "./pages/users/userPage"
import { CreateProductPage } from "./pages/products/createProductPage"
import { GalleryPage } from "./pages/galleryPage"






const Router = () => {
    return (
        <Routes>
            <Route path='/auth' element={<AuthPage />} />
            <Route element={<MainLayout />} >
                <Route path="/" element={<HomePage />} />
                <Route path="/users" element={<UsersPage />} />
                <Route path="/users/create" element={<CreateUserPage/>} />
                <Route path="/users/:id" element={<UserPage/>} />
                <Route path="/products" element={<ProductsPage/>} />
                <Route path="/products/create" element={<CreateProductPage/>} />
                <Route path="/gallery" element={<GalleryPage/>} />
            </Route>
        </Routes>
    )
}

export default Router