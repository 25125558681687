import { useQuery } from "@apollo/client"
import { Navigate, Outlet, useLocation } from "react-router"
import { GET_ADMIN } from "./entities/users"
import { Navbar } from "./widgets/navbar"
import { useEffect } from "react"




const MainLayout = () => {
  const location = useLocation()
    const {data, error} = useQuery(GET_ADMIN)  
    
    const path = location.pathname.split('/')[1]

    useEffect(()=> {
        sessionStorage.removeItem('table-page')
        sessionStorage.removeItem('table-pageSize')
    }, [path])
    
  
    if(data?.getAdmin?.login) {
     return (
       <div className="layout" >
         <Navbar/>
        <Outlet/>
       </div>
     )
    } else if(error) {
     return <Navigate to='/auth' replace={true}  />
    } else return null
}

export default MainLayout