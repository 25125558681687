import { graphql } from "../../../shared/gql";




export const GET_ADMIN = graphql(`
        query getAdmin {
            getAdmin {
                id
                login
            }
        }
    `)

export const CHECK_SUPERUSER = graphql(`
        query checkSuperUser {
            checkSuperUser
        }
    `)

export const CREATE_USER = graphql(`
    mutation createUser($input: CreateUserInput!) {
        createUser(createUserInput: $input){
            id
        }
    }
    `)  
    
export const UPDATE_USER = graphql(`
    mutation updateUser($input: UpdateUserInput!) {
        updateUser(updateUserInput: $input){
            id
        }
    }
    `) 

export const REMOVE_USER = graphql(`
    mutation removeUser($input: Int!) {
        removeUser(id: $input) {
            id
        }
    }
    `)

export const GET_USERS = graphql(`
    query getUsers($input: GetUsersArgs) {
          users(args: $input) {
             data {
                id
             login
             email
             social
             firstName
             secondName
             phone
             country
             city
             street
             house
             building
             entrance
             floor
             apartment
             intercom
             role
             },
              meta {
                pagination {
                page
                pageSize
                pageCount
                total
             }
              }
          }
        }
    `)

    export const GET_USER = graphql(`
        query getUser($input: Int!) {
            user(id: $input) {
             id
             login
             email
             social
             firstName
             secondName
             phone
             country
             city
             street
             house
             building
             entrance
             floor
             apartment
             intercom
             role
            }
        }
        `)