import { useEffect, useState } from 'react'
import styles from './ProductsPage.module.scss'
import { Button, Popconfirm, Select, Table, TableColumnsType } from 'antd'
import { Link, useNavigate } from 'react-router'
import { useMutation, useQuery } from '@apollo/client'
import { GET_PRODUCTS, REMOVE_PRODUCT } from '../../../../entities/product'
import { serverUrl } from '../../../../shared/constatns/serverUrl'
import Search from 'antd/es/input/Search'
import { Sort } from '../../../../shared/gql/graphql'
import { useDebounce } from 'use-debounce'



export const ProductsPage = () => {
const navigate = useNavigate()
const [pagination, setPagination] = useState<{page: number, pageSize: number}>({page: 1, pageSize: 10})
const [checkedItems, setCheckedItems] = useState<number[]>([])
const [orderBy, SetOrderBy] = useState<{field: string, value: Sort}>({field: 'createdAt', value: Sort.Asc})
const [search, setSearch] = useState<string>()
const [debouncedSearch] = useDebounce(search, 500)
const {data, loading, refetch} = useQuery(GET_PRODUCTS, {variables: {input: {pagination, search: debouncedSearch, orderBy}}, fetchPolicy: 'network-only'})
const [removeProduct] = useMutation(REMOVE_PRODUCT)
const products = data?.products?.data
const product = products?.[0]
const meta = data?.products?.meta

console.log(data)
const columns: TableColumnsType<typeof product> = [
  {
    title: 'ID',
    dataIndex: 'id'
  },
  {
  title: 'Превью',
  dataIndex: 'preview',
  render: (image)=> {
     if(image) {
      return <div className={styles.imgHolder} ><img width={'auto'} height={40} src={serverUrl + image?.url} /></div>
     }
  }
  },
  {
    title: 'Название',
    dataIndex: 'name'
  },
  {
    title: 'Категория',
    dataIndex: 'category'
  },
  {
    title: 'Особенность',
    dataIndex: 'feature'
  },
  {
    title: 'Новинка',
    dataIndex: 'new',
    render: (item)=> item ? 'Да' : 'Нет'
  },
  {
    title: 'Цена',
    dataIndex: 'price'
  },
  {
    title: 'Остаток',
    dataIndex: 'stock'
  }
]

useEffect(()=> {
  const page = sessionStorage.getItem('table-page')
  const pageSize = sessionStorage.getItem('table-pageSize')
  if(page && pageSize) {
    setPagination({page: +page, pageSize: +pageSize})
  }
}, [])

const onPageChange = (page: number, pageSize: number)=> {
  setPagination({page, pageSize})
  sessionStorage.setItem('table-page', page.toString())
  sessionStorage.setItem('table-pageSize', pageSize.toString())
} 

useEffect(()=> {
  if(meta?.pagination?.pageCount && (pagination.page > meta?.pagination?.pageCount)) {
    setPagination(prev=> ({...prev, page: meta?.pagination?.pageCount ?? pagination.page}))
  }
}, [meta?.pagination?.pageCount])

const onItemsDelete = async ()=> {
  await Promise.all(checkedItems?.map(async (id)=> {
    try {
     await removeProduct({variables: {input: id}})
    } catch(e) {
     console.error(e)
    }
   } ))

   refetch()
   setCheckedItems([])
}

  const handleOrderBy = (e: string)=> {
      const field = e.split(':')[0]
      const value = e.split(':')[1]
      SetOrderBy({field, value: value as Sort})
  }

  return (
    <main className={styles.root} >
          <div className={styles.header} >
        <h1>Товары</h1>
        <div className={styles.btnsWrapper} >
          { checkedItems.length > 0 && <Popconfirm
           title='Удаление'
           description='Вы действительно хотите удалить выбранные записи?'
           onConfirm={onItemsDelete}
          ><Button type='primary' danger >Удалить выбранные</Button></Popconfirm>}
           <Link to='/products/create' ><Button type='primary' >Добавить</Button></Link>
        </div>
      </div>
       
       <div className={styles.content} >
       <div className={styles.filters} >
          <Search onChange={(e)=> setSearch(e.target.value)} placeholder='Поиск' className={styles.search} />
          <div className={styles.sortWrapper} >
            <p>Сортировать по:</p>
            <Select onChange={handleOrderBy} className={styles.sort} defaultValue={'createdAt:asc'} options={[
              {label: 'Дата (возрастание)', value: 'createdAt:asc'},
              {label: 'Дата (убывание)', value: 'createdAt:desc'},
              {label: 'Название (возрастание)', value: 'name:asc'},
              {label: 'Название (убывание)', value: 'name:desc'}
            ]} />
          </div>
        </div>
       <Table
        className={styles.table}
        loading={loading}
        columns={columns}
        rowSelection={{ type: 'checkbox', onChange: (items)=> setCheckedItems(items as number[]) }}
        pagination={{hideOnSinglePage: products && (products?.length < 10), showSizeChanger: true, defaultPageSize: 10, total: meta?.pagination?.total, pageSize: pagination?.pageSize, current: pagination?.page, onChange: onPageChange }}
        onRow={(item)=> ({onDoubleClick: ()=> navigate(`/products/${item?.id}`)})}
        dataSource={products?.map((item)=> ({...item, key: item?.id}))}
        />
       </div>
    </main>
  )
}
