import { Button, Input } from 'antd'
import styles from './SignIn.module.scss'
import { useMutation } from '@apollo/client'
import { SIGN_IN } from '../../../../entities/auth'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import { useEffect } from 'react'
import { GET_ADMIN } from '../../../../entities/users'


type FormValues = {
  login?: string,
  password?: string
}

const SignIn = () => {
    const [signIn, data] = useMutation(SIGN_IN,
      {refetchQueries: [
        {query: GET_ADMIN}
      ],
      awaitRefetchQueries: true
    }
    )

    const navigate = useNavigate()
      const {control, handleSubmit, formState, setError} = useForm<FormValues>({
        defaultValues: {
          login: undefined,
          password: undefined,
        }
      })

      

      useEffect(()=> {
        if(data?.error?.message === 'Wrong credentials') {
          setError('password', {message: 'Неправильный логин или пароль'})
        }
      }, [data?.error?.message])


      const onSubmit: SubmitHandler<FormValues> = async (data)=> {
          const {login, password} = data
           if(login && password) {
               try {
                const {data} = await signIn({variables: {input: {login, password, remember: true}}})
                const token = data?.signIn?.jwt
                if(token) {
                  localStorage.setItem('jwt', token)
                  navigate('/', {replace: true})
                } 
               } catch(e){
                 console.error(e)
               }
           }
      }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root} action="">
    <h1 className={styles.title} >Авторизация</h1>
    <div className={styles.formItems} >
      <div className={styles.formItem} >
        <label htmlFor="login">Логин</label>
        <Controller
         name='login'
         control={control}
         render={({field})=> (
          <Input {...field} id='login' type='text' />
         )}
         />
      </div>
      <div className={styles.formItem} >
        <label htmlFor="login">Пароль</label>
        <Controller
         name='password'
         control={control}
         render={({field})=> (
          <Input {...field} id='password' type='text' />
         )}
         />
         <p className={styles.error} >{formState.errors.password?.message}</p>
      </div>
    </div>
    <Button htmlType='submit' size='large' className={styles.submitBtn} type='primary' >Авторизоваться</Button>
   </form>
  )
}

export default SignIn