import { useState } from 'react'
import styles from './ProductsPage.module.scss'
import { Button, Popconfirm } from 'antd'
import { Link } from 'react-router'
import { GalleryModal } from '../../../../widgets/galleryModal'

export const ProductsPage = () => {
const [checkedItems, setCheckedItems] = useState<React.Key[]>([])
const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
const [checkedImages, setCheckedImages] = useState<React.Key[]>([])


  return (
    <main className={styles.root} >
      <GalleryModal onChange={(value)=> setCheckedImages(value)} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
          <div className={styles.header} >
        <h1>Товары</h1>
        <div className={styles.btnsWrapper} >
          { checkedItems.length > 0 && <Popconfirm
           title='Удаление'
           description='Вы действительно хотите удалить выбранные записи?'
         
          ><Button type='primary' danger >Удалить выбранные</Button></Popconfirm>}
           <Link to='/users/create' ><Button type='primary' >Добавить</Button></Link>
        </div>
      </div>
       
       <div className={styles.content} >
        <Button onClick={()=> setIsModalOpen(true)} type='primary' >Добавить изображения</Button>
       </div>
    </main>
  )
}
