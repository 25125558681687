import { Modal, Upload, UploadFile } from 'antd'
import React, { FC, useState } from 'react'
import styles from './AddImagesModal.module.scss'
import { useMutation } from '@apollo/client'
import { FILE_UPLOAD } from '../../../../entities/upload'
import { GET_IMAGES } from '../../../../entities/images'
import { useNavigate } from 'react-router'

interface IProps {
    isModalOpen: boolean
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
    refetch: ()=> void
}

const AddImagesModal: FC<IProps> = ({isModalOpen, setIsModalOpen, refetch}) => {
    const [selectedImages, setSelectedImages] = useState<UploadFile<any>[]>([])
    const [uploadFile] = useMutation(FILE_UPLOAD, 
        {
            refetchQueries: [
                {query: GET_IMAGES}
            ]
        }
    )
    

    const onSubmit = async ()=> {
        try {
            await Promise.all(selectedImages.map( async (item)=> {
             await uploadFile( {variables: {file: item.originFileObj}})
            }))
            refetch()
            setIsModalOpen(false)
        } catch(e) {
            console.error(e)
        }
    }



  return (
    <Modal destroyOnClose okText='Загрузить выбранные' onOk={onSubmit} className={styles.root} title="Добавление изображений" centered width={'80vw'}  open={isModalOpen} onCancel={()=> setIsModalOpen(false)} >
        <div className={styles.content} >
            <Upload className={styles.upload} onChange={(e)=> setSelectedImages(e.fileList)} multiple showUploadList={{showPreviewIcon: false}} beforeUpload={file => false} listType='picture-card'> Добавить изображения </Upload>
        </div>
    </Modal>
  )
}

export default AddImagesModal