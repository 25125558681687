import { graphql } from "../../../shared/gql";



export const CREATE_PRODUCT = graphql(`
       mutation createProduct($input: CreateProductInput!) {
        createProduct(createProductInput: $input) {
            id
            name
            price
            stock
            feature
            category
            exclusive
            images {
                id
                name
                url
            }
            createdAt
        }
       }
    `)

export const UPDATE_PRODUCT = graphql(`
    mutation updateProduct($input: UpdateProductInput!) {
     updateProduct(updateProductInput: $input) {
         id
         name
         price
           stock
            feature
            category
            exclusive
         images {
             id
             name
             url
         }
         createdAt
     }
    }
 `)

export const REMOVE_PRODUCT = graphql(`
    mutation removeProduct($input: Int!) {
        removeProduct(id: $input) {
            id
        }
    }
 `)

export const GET_PRODUCTS = graphql(`
    query getProducts($input: GetProductsArgs!) {
        products(args: $input) {
          data {
            id
            name
            price
            stock
            feature
            category
            exclusive
            new
            preview {
                id
                name
                url
            }
            images {
                id
                name
                url
            }
            createdAt
          }
          meta {
           pagination {
            page
            pageSize
            pageCount
            total
           }
          }
        }
    }
    `)

export const GET_PRODUCT = graphql(`
    query getProduct($input: Int!) {
        product(id: $input) {
            id
            name
            price
            description
            stock
            feature
            category
            exclusive
            new
            preview {
                id
                name
                url
            }
            images {
                id
                name
                url
                createdAt
            }
            createdAt
          }
    }
    `)